import {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../shared/config/ConfigContext";
import {getPlot2bData} from "../../../slices/allServiceSlice";

const Plot2b = ({height, width, drugClass, timePoint, projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [data, setData] = useState([]);
    const radius = Math.min(width, height) / 2;
    const generateRandomColors = (count) => {
        const colors = [];
        for (let i = 0; i < count; i++) {
            const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            colors.push(color);
        }
        return colors;
    }
    useEffect(() => {
        dispatch(getPlot2bData({apiBaseUrl, projectId, pipelineId}))
        setData(allService.plots.plot2b.content)
    }, [dispatch, apiBaseUrl])
    useEffect(() => {
        const svg = d3.select(svgRef.current);
        if (data.length !== 0) {
            svg.attr("viewBox", [
                -width / 3,
                -height / 1.5,
                width,
                height * 1.25
            ]);
            const filteredData = data.filter(
                (row) => row.timepoint === timePoint && row.drug === drugClass
            );
            const sortedData = filteredData.sort((a, b) => b.abundance - a.abundance);
            const pie = d3.pie().value((d) => d.abundance);
            const customColors = d3.scaleOrdinal(generateRandomColors(100));
            svg
                .selectAll("path")
                .data(pie(filteredData))
                .enter()
                .append("path")
                .attr("d", d3.arc().innerRadius(0).outerRadius(radius))
                .attr("fill", (d, i) => customColors(i))
                .append("title")
                .text((d) => {
                    if (filteredData.includes(d.data)) {
                        return `${d.data.gene} : ${parseFloat(d.data.abundance).toFixed(3)}`;
                    }
                });
            const itemsPerColumn = 25;

            const legend = svg
                .selectAll(".legend")
                .data(sortedData.map((d) => d.gene))
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", (d, i) => {
                    const col = Math.floor(i / itemsPerColumn);
                    const x = col * 100 + 300;
                    const y = (i % itemsPerColumn) * 20 - 250;
                    return `translate(${x}, ${y})`;
                });
            legend
                .append("rect")
                .attr("x", -18)
                .attr("width", 18)
                .attr("height", 18)
                .attr("fill", (d, i) => customColors(i));

            legend
                .append("text")
                .attr("x", 4)
                .attr("y", 9)
                .attr("dy", ".35em")
                .attr("font-size", 16)
                .text((d) => d);

            svg
                .append("text")
                .attr("x", 0)
                .attr("y", -height / 2 - 20)
                .attr("text-anchor", "middle")
                .attr("font-size", 22)
                .text(`${drugClass} | ${timePoint}`);
        }
        return () => svg.selectAll('*').remove();
    }, [data, drugClass, timePoint])
    return (
        <svg ref={svgRef} width={width} height={height}/>
    )
}

export default Plot2b;