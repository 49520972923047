import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {currentUser} from "../../slices/authSlice";
import {useConfig} from "../config/ConfigContext";

export const withAuth = (OldComponent) => {
    return (props) => {
        const dispatch = useDispatch();
        const auth = useSelector(state => state.auth);
        const { apiBaseUrl } = useConfig();

        useEffect(() => {
            if (!auth.isLogin) {
                dispatch(currentUser(apiBaseUrl));
            }
        }, [dispatch, apiBaseUrl, auth.isLogin]);

        return (
            auth.loading ?
                <span className={"loading loading-lg text-info loading-position"} />
                :
                <OldComponent {...props} />
        );
    }
}