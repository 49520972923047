import Figure1 from "./Figure/Figure1";
import Figure2 from "./Figure/Figure2";
import Figure3 from "./Figure/Figure3";
import Figure4 from "./Figure/Figure4";
import Figure5 from "./Figure/Figure5";
import Figure6 from "./Figure/Figure6";
import Figure7 from "./Figure/Figure7";
import Figure8 from "./Figure/Figure8";
import Figure9 from "./Figure/Figure9";

const Visualization = (props) => {
    const projectId = props.match.params.projectId;
    const pipelineId = props.match.params.pipelineId;
    return (
        <div className="mx-36 my-4 px-12 py-8 bg-gray-100 min-w-[1200px]">
            <h1 className="text-4xl font-bold mb-8">
                Visualization of CIWARS pipeline outputs (v4)
            </h1>
            <Figure1 projectId={projectId} pipelineId={pipelineId} />
            <Figure2 projectId={projectId} pipelineId={pipelineId} />
            <Figure3 projectId={projectId} pipelineId={pipelineId} />
            <Figure4 projectId={projectId} pipelineId={pipelineId} />
            <Figure5 projectId={projectId} pipelineId={pipelineId} />
            <Figure6 projectId={projectId} pipelineId={pipelineId} />
            <Figure7 projectId={projectId} pipelineId={pipelineId} />
            <Figure8 projectId={projectId} pipelineId={pipelineId} />
            <Figure9 projectId={projectId} pipelineId={pipelineId} />
        </div>
    );
}

export default Visualization;