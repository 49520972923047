import {useEffect, useRef, useState} from "react";
import * as Plot from "@observablehq/plot";
import {useSelector} from "react-redux";

const Plot7b = ({width, height, option}) => {
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [arg, setArg] = useState([]);
    useEffect(() => {
        setArg(allService.plots.plot7b.content.filter(e => e.ARG === option))
    }, [option]);
    // useEffect(() => {
    //     Papa.parse(dataArgNormalAbundance, {
    //         download: true,
    //         header: true,
    //         dynamicTyping: true,
    //         delimiter: "",
    //         complete(result) {
    //             setArg(result.data.filter(e => e.ARG === option));
    //         }
    //     })
    // },[option])
    useEffect(() => {
        if (arg.length !== 0) {
            const plot = Plot.plot({
                color: {
                    range: ["blue"],
                    legend: true
                },
                x: {
                    tickRotate: -45,
                    ticks: 5,
                    tickSize: 5,
                    line: true,
                    tickPadding: 5,
                    label: "timepoint",
                    labelAnchor: "left",
                    labelOffset: 0,
                    nice: true
                },
                y: {
                    grid: true
                },
                marks: [
                    Plot.line(arg, {
                        sort: "timepoint",
                        x: "timepoint",
                        y: "abundance",
                        marker: "circle"
                    }),
                    Plot.dot(arg, {
                        sort: "timepoint",
                        x: "timepoint",
                        y: "abundance",
                        stroke: "is_anomaly",
                        fill: "is_anomaly"
                    })
                ],
                height,
                width,
                margin: 80
            });
            svgRef.current.append(plot);
            return () => plot.remove();
        }
    }, [arg])
    return (
        <div ref={svgRef} />
    )
}

export default Plot7b;