import {useSelector} from "react-redux";
import {formatDateTime, formatFileSize} from "../../../shared/utils/DataConverter";

const SampleTable = ({ setSample }) => {
    const allService = useSelector(state => state.allService);
    const deleteSampleHandler = (sample) => {
        setSample(sample);
        document.getElementById('delete_sample_confirm_modal').showModal();
    }
    return (
        <div>
            <table className={"table table zebra hidden md:block md:mt-8"}>
                <thead className={"text-sm text-gray-500"}>
                    <tr>
                        <th className={"w-[15vw]"}>Sample Name</th>
                        <th className={"w-[20vw]"}>Forward File Name</th>
                        <th className={"w-[20vw]"}>Reverse File Name</th>
                        <th className={"w-[10vw]"}>Forward GZip Size</th>
                        <th className={"w-[10vw]"}>Reverse GZip Size</th>
                        <th className={"w-[15vw]"}>Created At</th>
                        <th className={"w-[10vw]"}>Operation</th>
                    </tr>
                </thead>
                <tbody>
                {
                    allService.sample.length === 0 &&
                    <tr>
                        <td colSpan={7} className={"text-center text-xl py-4"}>
                            Empty
                        </td>
                    </tr>
                }
                {
                    allService.sample.map(e =>
                        <tr key={e["sample_id"]} className={"whitespace-nowrap"}>
                            <td className={"max-w-[15vw] truncate"}>
                                {e.name}
                            </td>
                            <td className={"max-w-[20vw] truncate"}>
                                {e['forward_file_name']}
                            </td>
                            <td className={"max-w-[20vw] truncate"}>
                                {e['reverse_file_name']}
                            </td>
                            <td className={"max-w-[10vw] truncate"}>
                                {formatFileSize(e['forward_file_zip_size'])}
                            </td>
                            <td className={"max-w-[10vw] truncate"}>
                                {formatFileSize(e['reverse_file_zip_size'])}
                            </td>
                            <td className={"max-w-[15vw] truncate"}>
                                {e['created_at'] && formatDateTime(e['created_at'])}
                            </td>
                            <td className={"max-w-[10vw] truncate"}>
                                <button
                                    onClick={() => deleteSampleHandler(e)}
                                    className={"btn btn-error btn-xs text-red-800"}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <div className={"grid grid-cols-1 gap-4 md:hidden"}>
                {
                    allService.sample.length === 0 &&
                    <div className={"bg-white p-6 rounded-lg shadow mt-4 flex items-center justify-center"}>
                        Empty
                    </div>
                }
                {
                    allService.sample.map(e =>
                        <div key={e["sample_id"]} className={"bg-white p-4 rounded-lg shadow mt-4 flex flex-col"}>
                            <div className={"flex justify-between items-center mb-2"}>
                                <span className={"font-bold truncate max-w-3/4 mr-2"}>Name: {e.name}</span>
                                <button
                                    onClick={() => deleteSampleHandler(e)}
                                    className={"btn btn-error btn-xs text-red-800"}
                                >
                                    Delete
                                </button>
                            </div>
                            <div className={"mb-2"}>
                                <div className={"text-sm"}>
                                    <div className={"truncate"}>
                                        <span className={"font-bold mr-2"}>Forward File:</span>
                                        <span className={"mr-2"}>{e['forward_file_name']}</span>
                                    </div>
                                    <div className={"text-xs text-gray-500"}>
                                        <span className={"font-bold"}>Forward File Size:</span>
                                        <span>{formatFileSize(e['forward_file_zip_size'])}</span>
                                    </div>
                                </div>
                                <div className={"text-sm"}>
                                    <div className={"truncate"}>
                                        <span className={"font-bold mr-2"}>Reverse File:</span>
                                        <span className={"mr-2"}>{e['reverse_file_name']}</span>
                                    </div>
                                    <div className={"text-xs text-gray-500"}>
                                        <span className={"font-bold"}>Reverse File Size:</span>
                                        <span>{formatFileSize(e['reverse_file_zip_size'])}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"mt-auto text-xs text-gray-500 text-right"}>
                                {e['created_at'] && formatDateTime(e['created_at'])}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>

    );
}

export default SampleTable;