import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import "./output.css"
import {RouteConstants} from "./shared/constants/RouteConstants";
import Home from "./components/Home/Home";
import About from "./components/Infos/About";
import Project from "./components/Project/Project";
import {ConfigContextProvider} from "./shared/config/ConfigContext";
import { Provider } from "react-redux";
import { store } from "./app/store";
import {withAuth} from "./shared/HOCs/withAuth";
import ProjectDetail from "./components/Project/ProjectDetail/ProjectDetail";
import JobDetail from "./components/Project/ProjectDetail/JobDetail/JobDetail";
import Visualization from "./components/Visualization/Visualization";

const root = document.getElementById('root');

fetch("/config/config.json")
    .then(r => r.json())
    .then(config => {
        ReactDOM.render(
            <Provider store={store}>
                <ConfigContextProvider config={config}>
                    <BrowserRouter>
                        <App>
                            <Switch>
                                <Route path={`${RouteConstants.jobsRoute}/:jobId`} component={ withAuth(JobDetail) }/>
                                <Route path={`${RouteConstants.projectsRoute}/:projectId`} component={ withAuth(ProjectDetail) }/>
                                <Route path={RouteConstants.projectsRoute} component={ withAuth(Project) }/>
                                <Route path={RouteConstants.aboutRoute} component={ withAuth(About) }/>
                                <Route path={`${RouteConstants.visualizeRoute}/:projectId/:pipelineId`} component={ withAuth(Visualization) }/>
                                <Route path={RouteConstants.homeRoute} component={ withAuth(Home) }/>
                                <Redirect to={RouteConstants.homeRoute}/>
                            </Switch>
                        </App>
                    </BrowserRouter>
                </ConfigContextProvider>
            </Provider>,
            root
        );
    })
    .catch(() => {
        alert("No config.json file found");
    });