import {useSelector} from "react-redux";
import {formatDateTime} from "../../../../shared/utils/DataConverter";

const StepTable = () => {
    const allService = useSelector(state => state.allService);
    return (
        <div>
            <table className={"table table zebra hidden md:block md:mt-8"}>
                <thead className={"text-sm text-gray-500"}>
                <tr>
                    <th className={"w-[15vw]"}>Step Name</th>
                    <th className={"w-[40vw]"}>Description</th>
                    <th className={"w-[10vw]"}>Status</th>
                    <th className={"w-[15vw]"}>Started At</th>
                    <th className={"w-[15vw]"}>Completed At</th>
                </tr>
                </thead>
                <tbody>
                {
                    allService.steps.length === 0 &&
                    <tr>
                        <td colSpan={5} className={"text-center text-xl py-4"}>
                            Empty
                        </td>
                    </tr>
                }
                {
                    allService.steps.map(e => (
                        <tr key={e["job_pipeline_step_id"]}>
                            <th className={"max-w-[15vw] truncate"}>{e.pipeline_step.name}</th>
                            <th className={"max-w-[40vw] truncate"}>{e.pipeline_step.description}</th>
                            <th className={"max-w-[10vw] truncate"}>{e.pipeline_step_status_type.name}</th>
                            <th className={"max-w-[15vw] truncate"}>{e.started_at && formatDateTime(e.started_at)}</th>
                            <th className={"max-w-[15vw] truncate"}>{e.terminated_at && formatDateTime(e.terminated_at)}</th>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div className={"grid grid-cols-1 gap-4 md:hidden"}>
                {
                    allService.steps.length === 0 &&
                    <div className={"bg-white p-6 rounded-lg shadow mt-4 flex items-center justify-center"}>
                        Empty
                    </div>
                }
                {
                    allService.steps.map(e => (
                        <div className={"bg-white p-6 rounded-lg shadow mt-4"}>
                            <div className={"truncate text-md"}>
                                <span className={"font-bold"}>Step: </span>
                                {e.pipeline_step.name}
                            </div>
                            <div className={"line-clamp-3 text-sm"}>
                                <span className={"font-bold"}>Description: </span>
                                {e.pipeline_step.description}
                            </div>
                            <div className={"text-sm"}>
                                <span className={"font-bold"}>Status: </span>
                                {e.pipeline_step_status_type.name}
                            </div>
                            <div className={"flex flex-col text-xs text-gray-500 my-2"}>
                                <div className={"truncate"}>
                                    <span className={"font-bold"}>Start: </span>
                                    {
                                        e['started_at'] ?
                                            formatDateTime(e['started_at'])
                                            :
                                            "Not Started"
                                    }
                                </div>
                                <div className={"truncate"}>
                                    <span className={"font-bold"}>Terminate: </span>
                                    {
                                        e['terminated_at'] ?
                                            formatDateTime(e['terminated_at'])
                                            :
                                            "Not Terminated"
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default StepTable;