import ciwarsLogo from "../../shared/images/CIWARS-LOGO.png";
import React from "react";
import {} from 'react-icons/ai';
import {RouteConstants} from "../../shared/constants/RouteConstants";
import {useDispatch, useSelector} from "react-redux";
import {logout, orcidLogin} from "../../slices/authSlice";
import {useConfig} from "../../shared/config/ConfigContext";
import EditProfileDialog from "./EditProfileDialog";
import {Link} from "react-router-dom";

const Navigation = () => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const auth = useSelector(state => state.auth);
    const toggleMenuHandler = (e) => {
        const navLinks = document.querySelector('.nav-links');
        navLinks.classList.toggle('top-[9%]');
    }
    const onLoginHandler = () => {
        dispatch(orcidLogin(apiBaseUrl));
    }
    const onLogoutHandler = () => {
        dispatch(logout(apiBaseUrl));
    }
    const editProfileHandler = () => {
        document.getElementById('edit_profile_modal').showModal();
    }
    return (
        <header className="bg-blue-500 py-3 fixed top-0 w-full z-50">
            <nav className="flex justify-between items-center w-[92%]  mx-auto">
                <div>
                    <img className="w-16" src={ciwarsLogo} alt="..."/>
                </div>
                <div
                    className="text-black font-bold nav-links duration-500 md:static absolute bg-inherit md:min-h-fit min-h-[60vh] left-0 top-[-100%] md:w-auto w-full flex items-center px-5 z-50">
                    <ul className="flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8">
                        <li>
                            <Link className={"px-4 py-2 rounded-md hover:bg-blue-200 transition-colors duration-300"} to={RouteConstants.homeRoute}>Home</Link>
                        </li>
                        {
                            !auth.loading && auth.isLogin &&
                            <li>
                                <Link className={"px-4 py-2 rounded-md hover:bg-blue-200 transition-colors duration-300"} to={RouteConstants.projectsRoute}>Project</Link>
                            </li>
                        }
                        <li>
                            <Link className={"px-4 py-2 rounded-md hover:bg-blue-200 transition-colors duration-300"} to={RouteConstants.aboutRoute}>About</Link>
                        </li>
                        {
                            !auth.loading && (auth.isLogin ?
                                <li className={"md:hidden"}>
                                    <span className={"hover:text-gray-500"} onClick={onLogoutHandler}>Sign out</span>
                                </li>
                                :
                                <li className={"md:hidden"}>
                                    <span className={"hover:text-gray-500"} onClick={onLoginHandler}>Sign in</span>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <div className="flex items-center gap-6">
                    {
                        !auth.loading && (auth.isLogin ?
                            <div className="dropdown dropdown-end">
                                <div className="bg-[#64B5F6] text-white px-5 py-2 rounded-full hover:bg-[#42A5F5] hover:cursor-pointer hidden md:flex justify-center max-w-xs">
                                    <label tabIndex={0} className="truncate hover:cursor-pointer">
                                        {auth.userInfo?.name || auth.userInfo?.email || auth.userInfo?.login}
                                    </label>
                                </div>
                                <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-36">
                                    <li><button onClick={editProfileHandler}>Edit Profile</button></li>
                                    <li><button onClick={onLogoutHandler}>Sign out</button></li>
                                </ul>
                            </div>
                            :
                            <button
                                className="bg-[#64B5F6] text-white px-5 py-2 rounded-full hover:bg-[#42A5F5] hidden md:flex"
                                onClick={onLoginHandler}
                            >
                                Sign in
                            </button>)
                    }
                    <label className="btn btn-circle swap swap-rotate md:hidden">
                        <input type="checkbox" onClick={toggleMenuHandler}/>
                        <svg className="swap-off fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"/></svg>
                        <svg className="swap-on fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"/></svg>
                    </label>
                </div>
            </nav>
            <EditProfileDialog/>
        </header>
    );
}

export default Navigation;