import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {resetUploadStatusBox, stopUploadSample} from "./slices/allServiceSlice";
import {useHistory} from "react-router-dom";
import {RouteConstants} from "./shared/constants/RouteConstants";

const UploadStatusBox = () => {
    const allService = useSelector(state => state.allService);
    const [boxShow, setBoxShow] = useState(true);
    const [statusBox , setStatusBox] = useState(true)
    const history = useHistory();
    const dispatch = useDispatch();
    const stopUploadHandler = (sample) => {
        dispatch(stopUploadSample({name: sample.name, id: sample.id}));
    }
    const goToProjectHandler = (sample) => {
        history.push(`${RouteConstants.projectsRoute}/${sample.projectId}`);
    }
    const toggleUploadStatus = () => {
        setBoxShow(!boxShow);
    }
    const checkStatusBoxDone = () => {
        setStatusBox(allService.sampleUploads.some(e => e.status === 'uploading'));
    }
    const closeStatusBox = () => {
        dispatch(resetUploadStatusBox());
    }
    useEffect(() => {
        checkStatusBoxDone();
    }, [allService.sampleUploads]);
    return (
        allService.uploadStatus &&
            <div
                className={`bg-white p-4 border rounded-lg shadow-md w-96 fixed bottom-0 right-0 mb-4 mr-4 z-40 ${!!allService.uploadStatus && 'md:block'} hidden`}>
                <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-semibold text-gray-500">Upload Status</span>
                    {
                        statusBox ?
                            <button
                                onClick={toggleUploadStatus}
                                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                {boxShow ? 'Hide' : 'Show'}
                            </button>
                            :
                            <button
                                onClick={closeStatusBox}
                            >
                                x
                            </button>
                    }
                </div>
                {
                    boxShow &&
                    <div className={"max-h-96 overflow-y-auto"}>
                        {
                            allService.sampleUploads.map(e => (
                                <div
                                    className="flex items-center justify-between my-1 space-x-2 border p-2 rounded-md">
                                    <div className="flex items-center w-1/4 overflow-hidden text-sm">
                                        <span className="truncate">{e.name}</span>
                                        <span>:</span>
                                    </div>

                                    <div className="flex items-center space-x-2 flex-grow">
                                        {e.status === "checkSum" &&
                                            <span className="text-sm truncate">Calculating Checksums...</span>
                                        }
                                        {e.status === "uploading" &&
                                            <div className="relative w-full">
                                                <progress
                                                    className="absolute top-1/2 transform -translate-y-1/2 w-full progress"
                                                    value={e.progress} max={100}></progress>
                                                <span
                                                    className="absolute right-0 top-0 text-xs text-gray-500 mr-2 mt-1">{e.progress}%</span>
                                            </div>
                                        }
                                        {
                                            e.status === "succeed" &&
                                            <span className="text-sm truncate">Done</span>
                                        }
                                        {
                                            e.status === "failed" &&
                                            <span className="text-sm truncate">Failed</span>
                                        }
                                    </div>
                                    {
                                        e.status === "uploading" &&
                                        <button
                                            onClick={() => stopUploadHandler(e)}
                                            className={"btn btn-xs btn-error text-white"}
                                        >
                                            Stop
                                        </button>
                                    }
                                    {(e.status === "succeed" || e.status === "failed") &&
                                        <button
                                            onClick={() => goToProjectHandler(e)}
                                            className="btn btn-xs btn-info text-white"
                                        >
                                            Check
                                        </button>
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
    );
};

export default UploadStatusBox;
