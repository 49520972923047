import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {downloadResultsArchive} from "../../../../slices/allServiceSlice";
import {normalizeString} from "../../../../shared/utils/DataConverter";
import ResultTable from "./ResultTable";

const Result = ({jobName, jobId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const downloadAllResultHandler = () => {
        dispatch(downloadResultsArchive({
            apiBaseUrl,
            jobId,
            fileName: (`${normalizeString(jobName)}_all_results.zip`),
        }));
    }
    return (
        <div className={"md:py-8 py-4"}>
            <div className="flex items-center">
                <div>
                    <h1 className={"font-bold lg:text-5xl text-3xl"}>Result</h1>
                </div>
                <div className={"ml-auto flex items-center"}>
                    <button
                        disabled={!allService.currJob?.terminated_at}
                        onClick={downloadAllResultHandler}
                        className={"btn btn-outline btn-accent lg:px-8 lg:btn-md btn-xs w-full lg:w-auto"}
                    >
                        Download All Results
                    </button>
                </div>
            </div>
            <ResultTable/>
        </div>
    );
}

export default Result;