import CreateProjectDialog from "./CreateProjectDialog";
import {useDispatch, useSelector} from "react-redux";
import {getProjects, getQuotaUsed} from "../../slices/allServiceSlice";
import {useConfig} from "../../shared/config/ConfigContext";
import {useEffect} from "react";
import {RouteConstants} from "../../shared/constants/RouteConstants";
import QuotaSizeBar from "./QuotaSizeBar";
import ProjectTable from "./ProjectTable";
import {Link} from "react-router-dom";

const Project = () => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    useEffect(() => {
        dispatch(getProjects(apiBaseUrl));
        dispatch(getQuotaUsed({apiBaseUrl}));
    }, [dispatch, allService.changed, apiBaseUrl]);
    const newProjectDialogHandler = () => {
        document.getElementById('create_project_modal').showModal();
    }
    return (
        <div className={"flex md:mt-8 flex-col md:mx-auto p-5 overflow-x-auto md:w-3/4 md:h-[85vh] overflow-y-auto"}>
            <div className="breadcrumbs md:mb-4 text-xs md:text-lg">
                <ul>
                    <li><Link to={RouteConstants.homeRoute}>Home</Link></li>
                    <li>Project</li>
                </ul>
            </div>
            <div className={"md:flow-root md:mb-4"}>
                <QuotaSizeBar/>
                <button className={"md:float-right btn btn-outline btn-info md:mb-4 md:right-0 md:px-8 md:btn-md btn-sm mb-0 w-full md:w-auto"} onClick={newProjectDialogHandler}>New Project</button>
            </div>
            <ProjectTable/>
            <CreateProjectDialog/>
        </div>
    );
}

export default Project;