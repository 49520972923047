import {formatDateTime} from "../../../shared/utils/DataConverter";
import {useSelector} from "react-redux";
import {RouteConstants} from "../../../shared/constants/RouteConstants";
import {Link} from "react-router-dom";

const JobTable = ({projectId}) => {
    const allService = useSelector(state => state.allService);
    return (
        <div>
            <table className={"table table zebra hidden md:block md:mt-8"}>
                <thead className={"text-sm text-gray-500"}>
                    <tr>
                        <th className={"w-[15vw]"}>Job Name</th>
                        <th className={"w-[15vw]"}>Sample</th>
                        <th className={"w-[15vw]"}>Pipeline</th>
                        <th className={"w-[10vw]"}>Status</th>
                        <th className={"w-[15vw]"}>Last Step Performed</th>
                        <th className={"w-[15vw]"}>Started At</th>
                        <th className={"w-[15vw]"}>Terminated At</th>
                    </tr>
                </thead>
                <tbody>
                {
                    allService.job.length === 0 &&
                    <tr>
                        <td colSpan={7} className={"text-center text-xl py-4"}>
                            Empty
                        </td>
                    </tr>
                }
                {
                    allService.job.map(e =>
                        <tr key={e["job_id"]} className={"whitespace-nowrap"}>
                            <td className={"max-w-[15vw] truncate"}>
                                <Link
                                    className={"font-bold text-blue-500 hover:underline"}
                                    to={`${RouteConstants.projectsRoute}/${projectId}/jobs/${e['job_id']}`}
                                >
                                    {e.name}
                                </Link>
                            </td>
                            <td className={"max-w-[15vw] truncate"}>
                                {e.sample.name}
                            </td>
                            <td className={"max-w-[15vw] truncate"}>
                                {e.pipeline.name}
                            </td>
                            <td className={"max-w-[10vw] truncate"}>
                                {e['job_status_type'].name}
                            </td>
                            <td className={"max-w-[15vw] truncate"}>
                                {e['mrt_job_pipeline_step'] && e['mrt_job_pipeline_step']['pipeline_step']['name']}
                            </td>
                            <td className={"max-w-[15vw] truncate"}>
                                {e['started_at'] && formatDateTime(e['started_at'])}
                            </td>
                            <td className={"max-w-[15vw] truncate"}>
                                {e['terminated_at'] && formatDateTime(e['terminated_at'])}
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <div className={"grid grid-cols-1 gap-4 md:hidden"}>
                {
                    allService.job.length === 0 &&
                    <div className={"bg-white p-6 rounded-lg shadow mt-4 flex items-center justify-center"}>
                        Empty
                    </div>
                }
                {
                    allService.job.map(e => (
                        <div className={"bg-white p-2 rounded-lg shadow mt-4"} key={e["job_id"]}>
                            <div className={"flex flex-col space-x-2 text-sm"}>
                                <div className={"truncate py-2 text-lg ml-2"}>
                                    <Link
                                        className={"font-bold text-blue-500 hover:underline"}
                                        to={`${RouteConstants.projectsRoute}/${projectId}/jobs/${e['job_id']}`}
                                    >
                                        {e.name}
                                    </Link>
                                </div>
                                <div className={"flex flex-row"}>
                                    <div className={"w-1/2 max-w-1/2 truncate mr-2"}>
                                        <span className={"font-bold"}>Sample: </span>{e.sample.name}
                                    </div>
                                    <div className={"w-1/2 max-w-1/2 truncate mr-2"}>
                                        <span className={"font-bold"}>Pipeline: </span>{e.pipeline.name}
                                    </div>
                                </div>
                                <div className={"flex flex-row"}>
                                    <div className={"w-1/2 max-w-1/2 truncate mr-2"}>
                                        <span className={"font-bold"}>Status: </span>{e['job_status_type'].name}
                                    </div>
                                    <div className={"w-1/2 max-w-1/2 truncate mr-2"}>
                                        <span className={"font-bold"}>Step: </span>
                                        {
                                            e['mrt_job_pipeline_step'] ?
                                                e['mrt_job_pipeline_step']['pipeline_step']['name']
                                                :
                                                "None"
                                        }
                                    </div>
                                </div>
                                <div className={"flex flex-col text-xs text-gray-500 my-2"}>
                                    <div className={"truncate"}>
                                        <span className={"font-bold"}>Start: </span>
                                        {
                                            e['started_at'] ?
                                                formatDateTime(e['started_at'])
                                                :
                                                "Not Started"
                                        }
                                    </div>
                                    <div className={"truncate"}>
                                        <span className={"font-bold"}>Terminate: </span>
                                        {
                                            e['terminated_at'] ?
                                                formatDateTime(e['terminated_at'])
                                                :
                                                "Not Terminated"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default JobTable;