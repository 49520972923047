import DeleteSampleConfirmDialog from "./DeleteSampleConfirmDialog";
import CreateSampleDialog from "./CreateSampleDialog";
import SampleTable from "./SampleTable";
import {useState} from "react";

const Sample = ({ projectId }) => {
    const [sample, setSample] = useState(null);
    const createSampleHandler = () => {
        document.getElementById('create_sample_modal').showModal();
    }
    return (
        <div className={"lg:mb-8 mb-4"}>
            <div className="flex items-center">
                <div>
                    <h1 className={"font-bold lg:text-5xl text-3xl"}>Sample</h1>
                </div>
                <div className={"ml-auto flex items-center"}>
                    <button
                        onClick={createSampleHandler}
                        className={"btn btn-outline btn-info lg:px-8 lg:btn-md btn-xs w-full lg:w-auto"}
                    >
                        New Sample
                    </button>
                </div>
            </div>
            <SampleTable setSample={setSample}/>
            <DeleteSampleConfirmDialog sample={sample}/>
            <CreateSampleDialog projectId={projectId}/>
        </div>
    )
}

export default Sample;