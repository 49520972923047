import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import axios from "axios";
import axiosWithAuth from "../shared/utils/axiosWithAuth";

const initialState = {
    loading: false,
    isLogin: false,
    userInfo: null,
    success: '',
    error: '',
}

export const orcidLogin = createAsyncThunk("orcidLogin", (apiBaseUrl) => {
    return axios.get(
        `${apiBaseUrl}/auth/orcid-login`,
        { withCredentials: true }
    ).then(res => {
        window.location.href = res.data.url;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const gitHubLogin = createAsyncThunk("gitHubLogin", (apiBaseUrl) => {
    return axios.get(
        `${apiBaseUrl}/auth/github-login`,
        { withCredentials: true }
    ).then(res => {
        window.location.href = res.data.url;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const logout = createAsyncThunk("logout", (apiBaseUrl) => {
    return axios.delete(
        `${apiBaseUrl}/auth/revoke-jwt-tokens`,
        { withCredentials: true }
    ).then(res => {
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const currentUser = createAsyncThunk("currentUser", (apiBaseUrl) => {
    return axios.get(
        `${apiBaseUrl}/user/current-user`,
        { withCredentials: true }
    ).then(res => {
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const refreshToken = createAsyncThunk('refreshToken', (apiBaseUrl) => {
    return axios.post(
        `${apiBaseUrl}/auth/refresh-jwt-tokens`,
        {},
        { withCredentials: true }
    ).then(res => {
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

export const updateUserInfo = createAsyncThunk('updateUserInfo', ({apiBaseUrl, requestBody}) => {
    return axiosWithAuth.put(
        `${apiBaseUrl}/user/current-user`,
        requestBody,
        { withCredentials: true }
    ).then(res => {
        if (!res.status) return Promise.reject(res);
        return res.data;
    }).catch(err => {
        throw new Error(err.response.data.detail);
    })
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(currentUser.fulfilled, (state, action) => {
            state.userInfo = action.payload;
            state.loading = false;
            state.isLogin = true;
        });
        builder.addCase(logout.fulfilled, state => {
            state.loading = false;
            state.isLogin = false;
        });
        builder.addCase(refreshToken.fulfilled, state => {
            state.loading = false;
            state.isLogin = true;
        });
        builder.addCase(updateUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload;
        });
        builder.addMatcher(
            isAnyOf(
                currentUser.pending,
                logout.pending,
                refreshToken.pending,
            ),
            state => {
                state.loading = true;
            }
        ).addMatcher(
            isAnyOf(
                orcidLogin.rejected,
                gitHubLogin.rejected,
                currentUser.rejected,
                logout.rejected,
                refreshToken.rejected,
            ),
            (state, action) => {
                state.loading = false;
                state.isLogin = false;
                state.error = action.error.message;
            }
        )
    },
});

export default authSlice.reducer;