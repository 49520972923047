import {useEffect, useRef, useState} from "react";
import {Legend} from "../../../shared/utils/Legend";
import * as d3 from "d3";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../shared/config/ConfigContext";
import {getPlot3Data} from "../../../slices/allServiceSlice";

const Plot3 = ({width, height, level, projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const correctionScoreRef = useRef();
    const [corrMap, setCorrMap] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    useEffect(() => {
        dispatch(getPlot3Data({apiBaseUrl, projectId, pipelineId, choice: level}))
    }, [dispatch, apiBaseUrl, level])
    useEffect(() => {
        setCorrMap(allService.plots.plot3.content)
    }, [allService.plots.plot3.content])
    useEffect(() => {
        setMyGroups([
            ...new Set(
                d3.map(corrMap, function (d) {
                    return d.row;
                })
            )
        ])
    }, [corrMap])
    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const x = d3.scaleBand().range([0, width]).domain(myGroups).padding(0.05);
        svg
            .append("g")
            .style("font-size", 10)
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x).tickSize(0))
            .selectAll("text")
            .attr("y", 0)
            .attr("x", -80)
            .attr("dy", "0.35em")
            .attr("transform", "rotate(270)")
            .select(".domain")
            .remove();
        const y = d3.scaleBand().range([height, 0]).domain(myGroups).padding(0.05);
        svg
            .append("g")
            .style("font-size", 10)
            .call(d3.axisRight(y).tickSize(0))
            .attr("transform", "translate(" + width + ",0)")
            .select(".domain")
            .remove();
        const myColor = d3
            .scaleSequential()
            .interpolator(d3.interpolateViridis)
            .domain([-1, 1]);
        const mouseover = function()  {
            d3.select(this).style("stroke", "black").style("opacity", 1);
        };
        const mouseleave = function() {
            d3.select(this).style("stroke", "none").style("opacity", 0.8);
        };
        let squares = svg
            .selectAll()
            .data(corrMap, function (d) {
                return d.row + ":" + d.col;
            })
            .enter()
            .append("rect")
            .attr("x", function (d) {
                return x(d.row);
            })
            .attr("y", function (d) {
                return y(d.col);
            })
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("width", x.bandwidth())
            .attr("height", y.bandwidth())
            .style("fill", function (d) {
                return myColor(d.val);
            })
            .style("stroke-width", 4)
            .style("stroke", "none")
            .style("opacity", 0.8)
            .on("mouseover", mouseover)
            .on("mouseleave", mouseleave);
        squares
            .append("title")
            .text(
                (d) =>
                    "Row :" +
                    d.col +
                    "\n" +
                    "Col: " +
                    d.row +
                    "\n\n" +
                    "Correlation : " +
                    Math.round(d.val*100000)/100000
            );
        return () => svg.selectAll('*').remove();
    }, [myGroups])
    useEffect(() => {
        if (correctionScoreRef.current) {
            const legendNode = Legend(d3.scaleSequential([-1, 1], d3.interpolateViridis), {
                title: "Correlation Score"
            })
            correctionScoreRef.current.appendChild(legendNode);
        }
        return () => {
            if (correctionScoreRef.current && correctionScoreRef.current.lastChild) {
                correctionScoreRef.current.removeChild(correctionScoreRef.current.lastChild);
            }
        };
    }, []);
    return (
        <div>
            <div ref={correctionScoreRef} className={"pl-4 py-4"}/>
            <svg ref={svgRef} width={width + 150} height={height + 150}/>
        </div>
    );
}

export default Plot3;