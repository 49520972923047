import {resetSnackBar} from "./slices/allServiceSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

const Toast = () => {
    const dispatch = useDispatch();
    const allService = useSelector(state => state.allService);
    useEffect(() => {
        if (allService.success || allService.error) {
            const timer = setTimeout(() => {
                dispatch(resetSnackBar());
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [allService.success, allService.error, dispatch])
    return (
        <div className="toast toast-bottom toast-end z-50">
            {
                (allService.success || allService.error) &&
                <div className={`alert md:text-md text-sm ${allService.success?"alert-success":"alert-error"}`}>
                    <span>{allService.success || allService.error}</span>
                    <button className={`btn btn-xs md:block hidden ${allService.success?"btn-success":"btn-error"}`} onClick={() => dispatch(resetSnackBar())}>x</button>
                </div>
            }
        </div>
    );
};

export default Toast;