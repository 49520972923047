import {RouteConstants} from "../../../shared/constants/RouteConstants";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../shared/config/ConfigContext";
import {useEffect, useRef} from "react";
import {
    downloadMetaData,
    downloadMetadataTemplate,
    getAProject, getJobs,
    getPipelines,
    getSamples,
    uploadMetaData
} from "../../../slices/allServiceSlice";
import EditProjectDialog from "./EditProjectDialog";
import DeleteProjectConfirmDialog from "./DeleteProjectConfirmDialog";
import Sample from "./Sample";
import Job from "./Job";
import {Link} from "react-router-dom";
import CreateVisualizationDialog from "./CreateVisualizationDialog";

const ProjectDetail = (props) => {
    const dispatch = useDispatch();
    const { apiBaseUrl, pollRefreshSeconds } = useConfig();
    const allService = useSelector(state => state.allService);
    const projectId = props.match.params.projectId;
    const inputRef = useRef(null);
    useEffect(() => {
        dispatch(getAProject({apiBaseUrl, projectId}));
        dispatch(getSamples({apiBaseUrl, projectId}));
        dispatch(getPipelines({apiBaseUrl}));

    }, [dispatch, allService.changed, apiBaseUrl, projectId]);
    useEffect(() => {
        const fetchJobs = () => {
            dispatch(getJobs({ apiBaseUrl, projectId }));
        };
        fetchJobs();
        const intervalId = setInterval(fetchJobs, pollRefreshSeconds * 1000);
        return () => clearInterval(intervalId);
    }, [dispatch, apiBaseUrl, allService.changed, projectId, pollRefreshSeconds]);
    const deleteProjectDialogHandler = () => {
        document.getElementById('delete_project_confirm_modal').showModal();
    }
    const editProjectDialogHandler = () => {
        document.getElementById('edit_project_modal').showModal();
    }
    const createVisualizationDialogHandler = () => {
        document.getElementById('create_visualization_selection_modal').showModal();
    }
    const getTemplateHandler = () => {
        dispatch(downloadMetadataTemplate({apiBaseUrl}));
    }
    const uploadButtonOnClickHandler = () => {
        inputRef.current.click();
    }
    const downloadMetaDataHandler = () => {
        dispatch(downloadMetaData({
            apiBaseUrl,
            projectId,
        }));
    }
    const uploadMetaDataHandler = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        e.target.value = null;
        const formData = new FormData();
        formData.append('metadata_file', file, file.name);
        await dispatch(uploadMetaData({apiBaseUrl: apiBaseUrl, projectId: projectId, requestBody: formData}));
    }
    return (
        <div className={"flex lg:mt-8 flex-col lg:mx-auto p-5 overflow-x-auto lg:w-3/4"}>
            <div className={"breadcrumbs flex-shrink-0 lg:mb-4 text-xs lg:text-lg"}>
                <ul>
                    <li><Link to={RouteConstants.homeRoute}>Home</Link></li>
                    <li><Link to={RouteConstants.projectsRoute}>Project</Link></li>
                    <li><span className={"max-w-[20vw] truncate"}>{allService.project.currProject?.name}</span></li>
                </ul>
            </div>
            <div className={"flex items-center"}>
                <h1 className={"font-bold lg:text-5xl text-3xl truncate max-w-3/4 lg:mr-8 mr-4 leading-normal lg:h-16"}>{allService.project.currProject?.name}</h1>
                <div className={"ml-auto flex flex-col lg:flex-row items-center"}>
                    <button
                        className={"btn btn-outline btn-accent lg:px-8 lg:btn-md btn-xs w-full lg:w-auto mb-2 lg:mb-0 lg:mr-4 lg:px-12 px-8"}
                        onClick={createVisualizationDialogHandler}
                        disabled={allService.job.length === 0}
                    >
                        Visualize
                    </button>
                    <button
                        className={"btn btn-outline btn-info lg:px-8 lg:btn-md btn-xs w-full lg:w-auto mb-2 lg:mb-0 lg:mr-4 lg:px-12 px-8"}
                        onClick={editProjectDialogHandler}
                    >
                        Edit
                    </button>
                    <button
                        className={"btn btn-outline btn-error lg:px-8 lg:btn-md btn-xs w-full lg:w-auto lg:px-12 px-8"}
                        onClick={deleteProjectDialogHandler}
                    >
                        Delete
                    </button>
                </div>
            </div>
            <div className={"py-4"}>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[150px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Affiliation :</span>
                    </div>
                    <span className={"flex-1"}>{allService.project.currProject?.affiliation || String.fromCharCode(160)}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[150px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Paper URL :</span>
                    </div>
                    <span className={"flex-1"}>{allService.project.currProject?.assoc_paper_url || String.fromCharCode(160)}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[150px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Pubmed ID :</span>
                    </div>
                    <span className={"flex-1"}>{(allService.project.currProject && allService.project.currProject['pubmed_id']) || String.fromCharCode(160)}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[150px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Contact Email :</span>
                    </div>
                    <span className={"flex-1"}>{(allService.project.currProject && allService.project.currProject['contact_email']) || String.fromCharCode(160)}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[150px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Description :</span>
                    </div>
                    <span className={"flex-1"}>{allService.project.currProject?.description || String.fromCharCode(160)}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[150px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Metadata :</span>
                    </div>
                    <span className={"flex-1"}>{allService.project.currProject?.metadata_file_name || 'No Metadata'}</span>
                    <div className={"pt-2 lg:pt-0"}>
                        <button
                            className={"btn btn-outline btn-accent lg:btn-md w-auto lg:px-8 lg:mx-2 btn-xs px-2 mx-2"}
                            onClick={getTemplateHandler}
                        >
                            Get Template
                        </button>
                        <button
                            className={`btn btn-outline btn-accent lg:btn-md w-auto lg:px-8 lg:mx-2 btn-xs px-2 mx-2 ${!allService.project.currProject?.metadata_file_name && "hidden"}`}
                            onClick={downloadMetaDataHandler}
                        >
                            Download
                        </button>
                        <button
                            className={"btn btn-outline btn-accent lg:btn-md w-auto lg:px-8 lg:mx-2 btn-xs px-2 mx-2"}
                            onClick={uploadButtonOnClickHandler}
                        >
                            <input
                                hidden
                                ref={inputRef}
                                type={"file"}
                                onChange={uploadMetaDataHandler}
                            />
                            Upload
                        </button>
                    </div>
                </div>
            </div>
            <Sample projectId={projectId}/>
            <Job projectId={projectId}/>
            <CreateVisualizationDialog projectId={projectId}/>
            <DeleteProjectConfirmDialog projectId={projectId}/>
            <EditProjectDialog projectId={projectId}/>
        </div>
    );
}

export default ProjectDetail;