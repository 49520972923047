import {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import {useSelector} from "react-redux";

const Plot6 = ({width, height, optionsMetadata, projectId, pipelineId}) => {
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [metaData, setMetaData] = useState([]);
    const meta_margin = ({ left: 60, top: 30, right: 20, bottom: 90 });
    useEffect(() => {
        setMetaData(allService.plots.plot6.content)
    }, [allService.plots.plot6.content])
    useEffect(() => {
        const svg = d3.select(svgRef.current);
        if (metaData.length !== 0) {
            const custom = d3
                .select("body")
                .append("div")
                .style("position", "absolute")
                .style("font-family", "'Arial', sans-serif")
                .style("font-size", "15px")
                .style("z-index", "10")
                .style("visibility", "hidden")
                .style("background-color", "white");
            svg
                .append("text")
                .attr("y", -5)
                .attr("x", -height / 2 + 40)
                .attr("transform", "rotate(-90)")
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .style("font-size", "15px")
                .text(optionsMetadata.md1);
            svg
                .append("text")
                .attr("y", width / 2)
                .attr("x", -height / 2 + 40)
                .attr("transform", "rotate(-90)")
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .style("font-size", "15px")
                .text(optionsMetadata.md2);
            svg
                .append("text")
                .attr("x", width / 2 - 300)
                .attr("y", height - 40)
                .style("font-size", "15px")
                .text("Sample Date");

            svg
                .append("text")
                .attr("x", width / 2 + 300)
                .attr("y", height - 40)
                .style("font-size", "15px")
                .text("Sample Date");
            const sp1 = svg.append("g").attr("transform", `translate(0,0)`);
            const sp2 = svg
                .append("g")
                .attr("transform", `translate(${(width - meta_margin.right) / 2},0)`);
            const xAxis1 = (g) =>
                g.attr("transform", `translate(0,${height - meta_margin.bottom})`).call(
                    d3
                        .axisBottom(x1_axisName) //x1
                        .ticks(width / 80)
                        .tickSizeOuter(0)
                );
            const x1_axisName = d3
                .scaleBand()
                .domain(metaData.map((d) => d.DOC)) // map categorical bands by Abbrevs
                .range([meta_margin.left, (width - meta_margin.right) / 2]);
            sp1
                .append("g")
                .attr("transform", `translate(0,${height - meta_margin.bottom})`)
                .call(xAxis1);
            sp1
                .selectAll("text")
                .attr("transform", "translate(-17,10) rotate(-30)");
            const y1 = d3
                .scaleLinear()
                .domain([0, d3.max(metaData, (d) => d[optionsMetadata.md1])])
                .nice()
                .range([height - meta_margin.bottom, meta_margin.top])
            const yAxis1 = (g) =>
                g
                    .attr("transform", `translate(${meta_margin.left},0)`)
                    .call(
                        d3
                            .axisLeft(y1)
                            .ticks(width / 80)
                            .tickSizeOuter(0)
                    )

                    .call((g) =>
                        g
                            .select(".tick:last-of-type text")
                            .clone()
                            .attr("x", 3)
                            .attr("text-anchor", "start")
                            .attr("font-weight", "bold")
                            .text(metaData.y1)
                    )
            sp1
                .append("g")
                .attr("transform", `translate(${meta_margin.left},0)`)
                .call(yAxis1);
            sp1
                .selectAll("dot")
                .data(metaData)
                .enter()
                .append("circle")
                .attr("cx", (d) => meta_margin.left - 30 + x1_axisName(d.DOC))
                .attr("cy", (d) => y1(d[optionsMetadata.md1]))
                .attr("r", 7)
                .attr("fill", "green")
                .on("mouseover", function (d2, i) {
                    d3.select(this).attr("fill", "red");
                    sp2.selectAll("circle").attr("fill", function (d1) {
                        if (d1.DOC === i.DOC) {
                            return "red";
                        } else {
                            return "orange";
                        }
                    });
                })
                .on("mouseout", function (d, i) {
                    d3.select(this).attr("fill", "green");
                    sp2.selectAll("circle").attr("fill", "orange");
                });
            sp2
                .append("g")
                .attr("transform", `translate(0,${height - meta_margin.bottom})`)
                .call(xAxis1);
            sp2
                .selectAll("text")
                .attr("transform", "translate(-17,10) rotate(-35)");
            const yscat1 = d3
                .scaleLinear()
                .domain([0, d3.max(metaData, (d) => d[optionsMetadata.md2])])
                .nice()
                .range([height - meta_margin.bottom, meta_margin.top]);
            const yAxis_scat = (g) =>
                g
                    .attr("transform", `translate(${meta_margin.left},0)`)
                    .call(
                        d3
                            .axisLeft(yscat1)
                            .ticks(width / 80)
                            .tickSizeOuter(0)
                    )
                    .call((g) =>
                        g
                            .select(".tick:last-of-type text")
                            .clone()
                            .attr("x", 3)
                            .attr("text-anchor", "start")
                            .attr("font-weight", "bold")
                            .text(metaData.yscat1)
                    );
            sp2
                .append("g")
                .attr("transform", `translate(${meta_margin.left},0)`)
                .call(yAxis_scat);
            sp2
                .selectAll("dot")
                .data(metaData)
                .enter()
                .append("circle")
                .attr("cx", (d) => meta_margin.left - 30 + x1_axisName(d.DOC))
                .attr("cy", (d) => yscat1(d[optionsMetadata.md2]))
                .attr("r", 7)
                .attr("fill", "orange")
                .on("mouseover", function (d, i) {
                    d3.select(this).attr("fill", "red");
                    sp1.selectAll("circle").attr("fill", function (d1) {
                        console.log(i);
                        if (d1.DOC === i.DOC) {
                            return "red";
                        } else {
                            return "green";
                        }
                    });
                })
                .on("mouseout", function () {
                    d3.select(this).attr("fill", "orange");
                    sp1.selectAll("circle").attr("fill", "green");
                    return custom.style("visibility", "hidden");
                });
        }
        return () => svg.selectAll('*').remove();
    }, [metaData, optionsMetadata])
    return (
        <div>
            <svg ref={svgRef} height={height} width={width}/>
        </div>
    );
}

export default Plot6;