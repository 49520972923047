import NSFLogo from "../../shared/images/NSF_Official_logo_400x.png";
import readDiagram from "../../shared/images/CIWARS_Read_Pipeline.png";
import assemblyDiagram from "../../shared/images/CIWARS_Assembly_Pipeline.png";
import {useRef} from "react";

const Home = () => {
    const openPopup = () => {
        window.open('https://youtu.be/4pTwP0H35BI', 'VideoPopupWindow', 'width=800, height=600');
    };
    const readPipelineRef = useRef(null);
    const assemblyPipelineRef = useRef(null);
    const scrollToHero = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const scrollToWhatWeDo = () => {
        readPipelineRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToPipelines = () => {
        assemblyPipelineRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className='text-black select-none'>
            <div className={'fixed flex flex-col justify-center right-0 mr-4 h-screen'}>
                <button onClick={scrollToHero}
                        className='bg-blue-400 w-[180px] rounded-md font-medium my-6 mx-auto py-3 text-white hover:bg-blue-500'>
                    Welcome
                </button>
                <button onClick={scrollToWhatWeDo}
                        className='bg-blue-400 w-[180px] rounded-md font-medium my-6 mx-auto py-3 text-white hover:bg-blue-500'>
                    Read based pipeline
                </button>
                <button onClick={scrollToPipelines}
                        className='bg-blue-400 w-[180px] rounded-md font-medium my-6 mx-auto py-3 text-white hover:bg-blue-500'>
                    Assembly based pipeline
                </button>
            </div>

            <div className={'bg-gradient-to-b from-blue-500 to-green-500'}>
                <div
                    className='max-w-[1000px] mx-auto h-screen text-center flex flex-col justify-center'>
                    <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
                        Welcome to CIWARS
                    </h1>
                    <div className='flex justify-center items-center'>
                        <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
                            Your AR Risk Indicator Computer
                        </p>
                    </div>
                    <p className='md:text-2xl text-xl font-bold text-gray-300'>Interactive Dashboards & Report
                        System</p>
                    <button onClick={openPopup}
                            className='bg-[#4CAF50] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white hover:bg-[#4CAF90]'>Tutorial
                    </button>
                </div>
            </div>
            <div className={'bg-gray-100'} ref={readPipelineRef}>
                <div className={'max-w-[1000px] mx-auto h-screen text-center flex flex-col justify-center'}>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold md:py-10'>
                        Read based pipeline structure
                    </h1>
                    <img src={readDiagram} alt={"..."} className={"w-auto bg-inherit"}/>
                </div>
            </div>

            <div
                className={'max-w-[1400px] mx-auto text-center flex md:flex-row flex-col justify-center items-center space-x-4'}
                ref={assemblyPipelineRef}
            >
                <div className={'max-w-[1000px] mx-auto h-screen text-center flex flex-col justify-center'}>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold md:py-10'>
                        Assembly based pipeline structure
                    </h1>
                    <img src={assemblyDiagram} alt={"..."} className={"w-auto"}/>
                </div>
            </div>
            <p className={'text-gray-400 text-center py-8 text-xl'}>Citation:AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</p>
            <a href="https://clustrmaps.com/site/1c079" title="Visit tracker" className={"fixed bottom-4 right-4"}>
                <img alt={""} src="//www.clustrmaps.com/map_v2.png?d=T8PRnyUnB5ecEorh4uLqxpdpPWE1KZttL3BuRPAdNhw&cl=ffffff"/>
            </a>
            <img src={NSFLogo} alt={"..."} className={"fixed w-36 bottom-0 left-0 md:w-48"}/>
        </div>
    );
}

export default Home;