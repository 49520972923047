import JobTable from "./JobTable";
import CreateJobDialog from "./CreateJobDialog";

const Job = ({projectId}) => {
    const createJobHandler = () => {
        document.getElementById('create_job_modal').showModal();
    }
    return (
        <div className={"lg:mb-8 mb-4"}>
            <div className="flex items-center">
                <div>
                    <h1 className={"font-bold lg:text-5xl text-3xl"}>Job</h1>
                </div>
                <div className={"ml-auto flex items-center"}>
                    <button
                        onClick={createJobHandler}
                        className={"btn btn-outline btn-info lg:px-8 lg:btn-md btn-xs w-full lg:w-auto"}
                    >
                        New Job
                    </button>
                </div>
            </div>
            <JobTable projectId={projectId}/>
            <CreateJobDialog/>
        </div>
    );
}
export default Job;