import {useDispatch, useSelector} from "react-redux";
import {formatFileSize} from "../../../../shared/utils/DataConverter";
import {setError} from "../../../../slices/allServiceSlice";

const ResultTable = () => {
    const dispatch = useDispatch();
    const allService = useSelector(state => state.allService);
    const downloadResultHandler = (result) => {
        if (result['output_file_url'])
            window.open(result['output_file_url'], '_blank');
        else
            dispatch(setError('Result file not found'));
    }
    return (
        <div>
            <table className={"table table zebra hidden md:block md:mt-8"}>
                <thead className={"text-sm text-gray-500"}>
                    <tr>
                        <th className={"w-[15vw]"}>Result Name</th>
                        <th className={"w-[75vw]"}>Description</th>
                        <th className={"w-[10vw]"}>File Size</th>
                    </tr>
                </thead>
                <tbody>
                {
                    allService.results.length === 0 &&
                    <tr>
                        <td colSpan={5} className={"text-center text-xl py-4"}>
                            Empty
                        </td>
                    </tr>
                }
                {
                    allService.results.map(e => (
                        <tr>
                            <td className={"max-w-[15vw] truncate"}>
                                <button
                                    className={"font-bold text-blue-500 hover:underline"}
                                    onClick={() => downloadResultHandler(e)}
                                >
                                    {e['output_file_name']}
                                </button>
                            </td>
                            <td className={"max-w-[75vw] truncate"}>{e.description}</td>
                            <td className={"max-w-[10vw] truncate"}>{formatFileSize(e['output_file_size'])}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div className={"grid grid-cols-1 gap-4 md:hidden"}>
                {
                    allService.results.length === 0 &&
                    <div className={"bg-white p-6 rounded-lg shadow mt-4 flex items-center justify-center"}>
                        Empty
                    </div>
                }
                {
                    allService.results.map(e => (
                        allService.results.map(e => (
                            <div className={"bg-white p-6 rounded-lg shadow mt-4 flex flex-col"}>
                                <div className={"truncate text-md"}>
                                    <span className={"font-bold"}>Result: </span>
                                    {e['output_file_name']}
                                </div>
                                <div className={"line-clamp-3 text-sm"}>
                                    <span className={"font-bold"}>Description: </span>
                                    {e.description}
                                </div>
                                <div className={"text-xs text-gray-500 mt-auto text-right"}>
                                    {formatFileSize(e['output_file_size'])}
                                </div>
                            </div>
                        ))
                    ))
                }
            </div>
        </div>
    );
}

export default ResultTable;