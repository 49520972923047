import {deleteSample} from "../../../slices/allServiceSlice";
import {useDispatch} from "react-redux";
import {useConfig} from "../../../shared/config/ConfigContext";

const DeleteSampleConfirmDialog = ( { sample } ) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const onConfirmHandler = () => {
        dispatch(deleteSample({apiBaseUrl: apiBaseUrl, sampleId: sample['sample_id']}));
        onCloseHandler();
    }
    const onCloseHandler = () => {
        document.getElementById('delete_sample_confirm_modal').close();
    }
    return (
        <dialog id={"delete_sample_confirm_modal"} className={"modal md:modal-middle"}>
            <div className={"modal-box flex flex-col items-center"}>
                <div className={"text-center md:text-lg mt-2 w-11/12"}>
                    {`Are you sure you want to delete the sample `}
                    <span className={"break-words"}>
                        {`"${sample?.name}"?`}
                    </span>
                </div>
                <div className={"text-center text-error md:text-md sm:text-sm text-xs mt-2"}>
                    Warning: This will delete the Sample as well as any Jobs that were run using the Sample. This operation cannot be undone.
                </div>
                <div className={"mt-2 flex"}>
                    <button
                        className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-error"}
                        onClick={onConfirmHandler}
                    >
                        Confirm
                    </button>
                    <button
                        className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                        onClick={onCloseHandler}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </dialog>
    );
}
export default  DeleteSampleConfirmDialog;