import {RouteConstants} from "../../shared/constants/RouteConstants";
import {convertGBToBytes, formatDateTime, formatFileSize} from "../../shared/utils/DataConverter";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const ProjectTable = () => {
    const allService = useSelector(state => state.allService);
    return (
        <div>
            <table className={"table table-zebra hidden md:block"}>
                <thead className={"text-sm text-gray-500"}>
                <tr>
                    <th className={"w-[15vw]"}>Project</th>
                    <th className={"w-[25vw]"}>Description</th>
                    <th className={"w-[10vw]"}>Metadata File</th>
                    <th className={"w-[5vw]"}>Storage Space</th>
                    <th className={"w-[5vw]"}>Active Job Count</th>
                    <th className={"w-[15vw]"}>Created At</th>
                </tr>
                </thead>
                <tbody>
                {
                    allService.project.project.length === 0 &&
                    <tr>
                        <td colSpan={6} className={"text-center text-xl py-4"}>
                            Empty
                        </td>
                    </tr>
                }
                {
                    allService.project.project.map(e => (
                        <tr key={e["project_id"]} className={"whitespace-nowrap"}>
                            <td className={"max-w-[15vw] truncate"}>
                                <Link to={`${RouteConstants.projectsRoute}/${e["project_id"]}`} className={"font-bold text-blue-500 hover:underline"}>
                                    {e.name}
                                </Link>
                            </td>
                            <td className={"max-w-[25vw] truncate"}>
                                {e.description}
                            </td>
                            <td className={"max-w-[10vw] truncate"}>
                                {e["metadata_file_name"]}
                            </td>
                            <td>
                                {formatFileSize(convertGBToBytes(e["sample_zip_space"]))}
                            </td>
                            <td>
                                {e["active_job_count"]}
                            </td>
                            <td>
                                {formatDateTime(e["created_at"])}
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div className={"grid grid-cols-1 gap-4 md:hidden"}>
                {
                    allService.project.length === 0 &&
                    <div className={"bg-white p-6 rounded-lg shadow mt-4 flex items-center justify-center"}>
                        Empty
                    </div>
                }
                {
                    allService.project.project.map(e => (
                        <div className={"bg-white p-4 rounded-lg shadow mt-4"} key={e["project_id"]}>
                            <div className={"flex flex-col space-x-2 text-sm"}>
                                <div className={"w-[60vw] truncate mx-auto py-2 text-xl"}>
                                    <Link to={`${RouteConstants.projectsRoute}/${e["project_id"]}`} className={"font-bold text-blue-500 hover:underline"}>
                                        {e.name}
                                    </Link>
                                </div>
                                <div className={"break-words px-auto py-1 line-clamp-3"}>
                                    <span className={"font-bold"}>Description: </span>{e.description}
                                </div>
                                <div className={"w-[50vw] truncate mx-auto py-1"}>
                                    <span className={"font-bold"}>File: </span>{e["metadata_file_name"]}
                                </div>
                                <div className={"flex"}>
                                    <div className={"w-1/2 whitespace-nowrap"}><span className={"font-bold"}>Space: </span>{formatFileSize(convertGBToBytes(e["sample_zip_space"]))}</div>
                                    <div className={"w-1/2 whitespace-nowrap"}><span className={"font-bold"}>Active: </span>{e["active_job_count"]}</div>
                                </div>
                                <div className={"w-auto mr-2 whitespace-nowrap"}><span className={"font-bold"}>Created: </span>{formatDateTime(e["created_at"])}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default ProjectTable;