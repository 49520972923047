import {useSelector} from "react-redux";

const QuotaSizeBar = () => {
    const allService = useSelector(state => state.allService);
    return (
        <div className={"p-5 2xl:border-2 2xl:border-gray-500 2xl:rounded-2xl float-left 2xl:w-1/3"}>
            <div className={"hidden 2xl:block"}>
                <progress className={`mt-0 pt-0 progress ${allService.quota?.zip_space_used/allService.quota?.quota_size * 100 >= 90?"progress-error":"progress-info"}`} value={allService.quota?.zip_space_used/allService.quota?.quota_size * 100} max={100}></progress>
                <div className={"font-bold text-gray-500 flow-root"}>
                    <span className={"float-left whitespace-nowrap"}>Space Used: {allService.quota?.zip_space_used} GB</span>
                    <span className={"float-right whitespace-nowrap"}>Space left: {allService.quota?.quota_size-allService.quota?.zip_space_used} GB</span>
                </div>
            </div>
            <div className="block 2xl:hidden flex mx-auto items-center p-4 w-[80vw] sm:w-auto">
                <div className="mr-auto sm:mr-8">
                    <div className={`radial-progress ${allService.quota?.zip_space_used/allService.quota?.quota_size * 100 >= 90 ? "text-error" : "text-info"}`} style={{ "--value": allService.quota?.zip_space_used/allService.quota?.quota_size*100, "--size": "5rem"}}>
                        {Math.round(allService.quota?.zip_space_used / allService.quota?.quota_size*10000)/100}%
                    </div>
                </div>
                <div className={"sm:ml-8"}>
                    <div className="whitespace-nowrap text-md font-bold">Space Used: {allService.quota?.zip_space_used} GB</div>
                    <div className="whitespace-nowrap text-md font-bold">Space left: {allService.quota?.quota_size-allService.quota?.zip_space_used} GB</div>
                </div>
            </div>
        </div>
    );
}

export default QuotaSizeBar;