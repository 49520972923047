import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authSlice from '../slices/authSlice';
import allServiceSlice from '../slices/allServiceSlice';

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
});

export const store = configureStore({
    reducer: {
        auth: authSlice,
        allService: allServiceSlice,
    },
    middleware: customizedMiddleware,
});
