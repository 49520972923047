import axios from "axios";

const axiosWithAuth = axios.create({});

const tokenCheck = async (err) => {
    try {
        const {apiBaseUrl} = await fetch('/config.json').then(res => res.json());
        await axios.post(`${apiBaseUrl}/auth/refresh-jwt-tokens`, {}, {withCredentials: true});
        return axios(err.config);
    } catch (err) {
        window.location.href = '/';
    }
}
axiosWithAuth.interceptors.response.use(res => res,
    async err => {
        let refresh;
        if (err.response?.status === 401)
            refresh = await tokenCheck(err);
        return refresh || err;
    })

export default axiosWithAuth;