import {useEffect, useState} from "react";
import Plot6 from "../Plot/Plot6";
import {useDispatch, useSelector} from "react-redux";
import {getPlot6Data} from "../../../slices/allServiceSlice";
import {useConfig} from "../../../shared/config/ConfigContext";

const Figure6 = ({projectId, pipelineId}) => {
    const [optionsMetadata, setOptionsMetadata] = useState({md1: '', md2: ''});
    const [metadataChoice, setMetadataChoice] = useState([])
    const allService = useSelector(state => state.allService);
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    useEffect(() => {
        dispatch(getPlot6Data({apiBaseUrl, projectId, pipelineId}))
        if (allService.plots.plot6.content.length > 0) {
            const choices = Object.keys(allService.plots.plot6.content[0]).filter(e => e !== 'Sample' && e !== 'DOC')
            setMetadataChoice(choices);
        }
    }, []);
    useEffect(() => {
        if (metadataChoice.length > 0)
            setOptionsMetadata({md1: metadataChoice[0], md2: metadataChoice[0]})
    }, [metadataChoice]);
    const md1OnChangeHandler = (event) => {
        setOptionsMetadata({...optionsMetadata, md1: event.target.value});
    }
    const md2OnChangeHandler = (event) => {
        setOptionsMetadata({...optionsMetadata, md2: event.target.value});
    }
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 6</h2>
            <h2 className="text-xl font-bold mt-4 mb-2">Visualize the features in metadata along timepoints</h2>
            <p>The below figure shows the trend for the features in meta data. User can select two features to visualize simultaneously. If user mouse over each point, the other feature value corresponding to the same timepoint denoted as red</p>
            {
                metadataChoice.length > 0 ?
                    <div>
                        <div className={"flex flex-row my-4"}>
                            <div className="form-control max-w-xs mr-12">
                                <label className="label">
                                    <span className="label-text">Choose MD1</span>
                                </label>
                                <select className="select select-bordered w-xs" value={optionsMetadata.md1}
                                        onChange={md1OnChangeHandler}>
                                    {
                                        metadataChoice.map(e => (
                                            <option value={e}>{e}</option>
                                        ))
                                    }
                                </select>
                                <label className="label">
                                    <span className="label-text-alt text-gray-400 italic">Please select the feature</span>
                                </label>
                            </div>
                            <div className="form-control w-xs">
                                <label className="label">
                                    <span className="label-text">Choose MD2</span>
                                </label>
                                <select className="select select-bordered w-xs" value={optionsMetadata.md2}
                                        onChange={md2OnChangeHandler}>
                                    {
                                        metadataChoice.map(e => (
                                            <option value={e}>{e}</option>
                                        ))
                                    }
                                </select>
                                <label className="label">
                                    <span className="label-text-alt text-gray-400 italic">Please select the feature</span>
                                </label>
                            </div>
                        </div>
                        <Plot6 width={1152} height={440} optionsMetadata={optionsMetadata} />
                    </div>
                    :
                    <div className={"mb-12"}>
                        <h2 className="text-xl font-bold mt-4 mb-2 text-red-500">Metadata file has no features to visualize</h2>
                    </div>
            }

        </div>
    )
}

export default Figure6;