import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {createJob} from "../../../slices/allServiceSlice";
import {useConfig} from "../../../shared/config/ConfigContext";

const CreateJobDialog = () => {
    const dispatch = useDispatch();
    const {apiBaseUrl} = useConfig();
    const allService = useSelector(state => state.allService);
    const formik = useFormik({
        initialValues: {
            'sample_id': -1,
            'pipeline_id': -1,
            'name': '',
        },
        onSubmit: async (values) => {
            const result = await dispatch(createJob({apiBaseUrl: apiBaseUrl, requestBody: values}));
            if (result)
                onCloseHandler();
        },
        validationSchema: Yup.object().shape({
            'name': Yup.string()
                .required('Please enter your job name'),
            'sample_id': Yup.number()
                .min(1,'Please select a sample'),
            'pipeline_id': Yup.number()
                .min(1,'Please select a pipeline'),
        })
    });
    const onCloseHandler = () => {
        formik.resetForm();
        document.getElementById('create_job_modal').close();
    }
    return (
        <dialog id={"create_job_modal"} className={"modal md:modal-middle"}>
            <div className={"modal-box"}>
                <form
                    method={"dialog"}
                    className={"flex flex-col items-center py-4"}
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className={"mb-8 font-bold text-xl md:text-3xl"}>Create Job</h1>
                    <div className={"form-control w-full max-w-xs"}>
                        <input
                            id={"name"}
                            type={"text"}
                            placeholder={"Job Name*"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.name && !!formik.errors.name && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.name && formik.errors.name}</span>
                        </label>
                    </div>
                    <div className={"form-control w-full max-w-xs"}>
                        <select
                            id={"sample_id"}
                            className={"select select-bordered md:select-md select-sm w-full max-w-xs"}
                            value={formik.values['sample_id'] || ''}
                            onChange={formik.handleChange}
                        >
                            <option disabled value={-1}>
                                Pick your Sample
                            </option>
                            {
                                allService.sample.map(e => (
                                    <option
                                        key={e['sample_id']}
                                        value={e['sample_id']}
                                    >
                                        {e.name}
                                    </option>
                                ))
                            }
                        </select>
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched["sample_id"] && formik.errors["sample_id"]}</span>
                        </label>
                    </div>
                    <div className={"form-control w-full max-w-xs"}>
                        <select
                            id={"pipeline_id"}
                            className={"select select-bordered md:select-md select-sm w-full max-w-xs"}
                            value={formik.values['pipeline_id'] || ''}
                            onChange={formik.handleChange}
                        >
                            <option disabled value={-1}>
                                Pick your Pipeline
                            </option>
                            {
                                allService.pipeline.map(e => (
                                    <option
                                        key={e['pipeline_id']}
                                        value={e['pipeline_id']}
                                        title={e.description}
                                    >
                                        {e.name}
                                    </option>
                                ))
                            }
                        </select>
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched["pipeline_id"] && formik.errors["pipeline_id"]}</span>
                        </label>
                    </div>
                    <div>
                        <button
                            type={"submit"}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-info"}
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </button>
                        <button
                            type={"button"}
                            onClick={onCloseHandler}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    )
}

export default CreateJobDialog;