import StepTable from "./StepTable";

const Step = () => {
    return (
        <div className={"py-4"}>
            <h1 className={"font-bold lg:text-5xl text-3xl"}>Step</h1>
            <StepTable/>
        </div>
    )
}

export default Step;