import {useDispatch, useSelector} from "react-redux";
import {deleteProject} from "../../../slices/allServiceSlice";
import {useConfig} from "../../../shared/config/ConfigContext";
import {RouteConstants} from "../../../shared/constants/RouteConstants";
import {useHistory} from "react-router-dom";

const DeleteProjectConfirmDialog = ({ projectId }) => {
    const allService = useSelector(state => state.allService);
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const history = useHistory();
    const onConfirmHandler = async () => {
        const result = await dispatch(deleteProject({apiBaseUrl: apiBaseUrl, projectId: projectId}));
        if (result)
            history.push(RouteConstants.projectsRoute);
        else
            onCloseHandler();
    }
    const onCloseHandler = () => {
        document.getElementById('delete_project_confirm_modal').close();
    }
    return (
        <dialog id={"delete_project_confirm_modal"} className={"modal md:modal-middle"}>
            <div className={"modal-box flex flex-col items-center"}>
                <div
                    className={"text-center md:text-lg mt-2 w-11/12"}
                >
                    {`Are you sure you want to delete the project `}
                    <span className={"break-words"}>
                        {`"${allService.project.currProject?.name}" ?`}
                    </span>
                </div>
                <div
                    className={"text-error md:text-md sm:text-sm text-xs"}
                >
                    Warning: This operation cannot be undone!
                </div>
                <div className={"mt-2"}>
                    <button
                        className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-error"}
                        onClick={onConfirmHandler}
                    >
                        Confirm
                    </button>
                    <button
                        className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                        onClick={onCloseHandler}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </dialog>
    );
}

export default DeleteProjectConfirmDialog;