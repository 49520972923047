import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../shared/config/ConfigContext";
import {updateUserInfo} from "../../slices/authSlice";
import {setError, setSuccess} from "../../slices/allServiceSlice";
import * as Yup from "yup";
import {useEffect} from "react";

const EditProfileDialog = () => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const auth = useSelector(state => state.auth);
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            affiliation: ''
        },
        onSubmit: async (values) => {
            const res = await dispatch(updateUserInfo({
                apiBaseUrl,
                requestBody: values
            }));
            if (res.type.endsWith('fulfilled'))
                dispatch(setSuccess('Profile has been updated'));
            else if (res.type.endsWith('rejected')) {
                dispatch(setError(res.error.message));
            }
            document.getElementById('edit_profile_modal').close();
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Please enter a valid email address.')
        })
    })
    useEffect(() => {
        formikResetHandler();
    }, [auth.userInfo])
    const formikResetHandler = () => {
        formik.setValues({
            name: auth.userInfo?.name,
            email: auth.userInfo?.email,
            affiliation: auth.userInfo?.affiliation
        });
    }
    const onCloseHandler = () => {
        document.getElementById('edit_profile_modal').close();
        formikResetHandler();
    }
    return (
        <dialog id="edit_profile_modal" className="modal md:modal-middle">
            <div className="modal-box">
                <form
                    method={"dialog"}
                    className={"flex flex-col items-center py-4"}
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className={"mb-8 font-bold text-xl md:text-3xl"}>Edit Project</h1>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"name"}
                            type={"text"}
                            placeholder={"Name"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.name && !!formik.errors.name && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.name && formik.errors.name}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"email"}
                            type={"text"}
                            placeholder={"Email"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.email && !!formik.errors.email && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.email && formik.errors.email}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"affiliation"}
                            type={"text"}
                            placeholder={"Affiliation"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.affiliation && !!formik.errors.affiliation && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.affiliation}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.affiliation && formik.errors.affiliation}</span>
                        </label>
                    </div>
                    <div>
                        <button
                            type={"submit"}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-info"}
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </button>
                        <button
                            type={"button"}
                            onClick={onCloseHandler}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    );
}

export default EditProfileDialog;