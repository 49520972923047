import {useDispatch, useSelector} from "react-redux";
import {stopAJob} from "../../../../slices/allServiceSlice";
import {useConfig} from "../../../../shared/config/ConfigContext";

const StopJobConfirmDialog = ({jobId}) => {
    const allService = useSelector(state => state.allService);
    const dispatch = useDispatch();
    const {apiBaseUrl} = useConfig();
    const onCloseHandler = () => {
        document.getElementById('stop_job_confirm_modal').close();
    }
    const onConfirmHandler = async () => {
        const result = await dispatch(stopAJob({apiBaseUrl, jobId}));
        if (result)
            onCloseHandler();
    }
    return (
        <dialog id={"stop_job_confirm_modal"} className={"modal md:modal-middle"}>
            <div className={"modal-box flex flex-col items-center"}>
                <div
                    className={"md:text-lg mt-2"}
                >
                    {`Are you sure you want to stop the job container "${allService.currJob?.name}"?`}
                </div>
                <div
                    className={"text-error md:text-md sm:text-sm text-xs"}
                >
                    Warning: This operation cannot be undone!
                </div>
                <div className={"mt-2"}>
                    <button
                        className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-error"}
                        onClick={onConfirmHandler}
                    >
                        Confirm
                    </button>
                    <button
                        className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                        onClick={onCloseHandler}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </dialog>
    );
}

export default StopJobConfirmDialog;