import {useFormik} from "formik";
import {QuotaCheck} from "../../../shared/utils/QuotaCheck";
import {useDispatch, useSelector} from "react-redux";
import {checkSumSample, createSample, setError} from "../../../slices/allServiceSlice";
import {useConfig} from "../../../shared/config/ConfigContext";
import * as Yup from "yup";
import {useRef, useState} from "react";
import jsSHA from "jssha";
import axios from "axios";

const CreateSampleDialog = ({projectId}) => {
    const dispatch = useDispatch();
    const allService = useSelector(state => state.allService);
    const { apiBaseUrl } = useConfig();
    const [index, setIndex] = useState(0);
    const forwardFileInput = useRef(null);
    const reverseFileInput = useRef(null);
    const formik = useFormik({
        initialValues: {
            'sample_name': '',
            'forward_zip_file': null,
            'reverse_zip_file': null,
        },
        onSubmit: async (values) => {
            onCloseHandler();
            if (await QuotaCheck(values.forward_zip_file.size + values.reverse_zip_file.size ,apiBaseUrl)) {
                dispatch(setError('You have excceed the quota size'));
            } else if (await allService.sampleUploads.find(e => (e.name === values['sample_name'] && (e.status === 'uploading' || e.status === 'checkSum')))) {
                dispatch(setError(`A sample by this name is already uploading`));
            } else if (await allService.sample.find(e => e.name === values.sample_name)) {
                dispatch(setError(`A sample by this name already exists`));
            }else {
                setIndex(index + 1);
                const formData = new FormData();
                const cancelTokenSource = axios.CancelToken.source();
                await dispatch(checkSumSample({
                    name: values['sample_name'],
                    cancelTokenSource: cancelTokenSource,
                    id: index,
                    projectId: projectId,
                    projectName: allService.project.currProject?.name
                }));
                const forwardCheckSum = await calculateSHA1Checksum(values['forward_zip_file']);
                const reverseCheckSum = await calculateSHA1Checksum(values['reverse_zip_file']);
                formData.append("sample_name", values['sample_name']);
                formData.append("forward_zip_checksum", forwardCheckSum);
                formData.append("reverse_zip_checksum", reverseCheckSum);
                formData.append("forward_zip_file", values['forward_zip_file'], values['forward_zip_file'].name);
                formData.append("type", "application/zip");
                formData.append("reverse_zip_file", values['reverse_zip_file'], values['reverse_zip_file'].name);
                formData.append("type", "application/zip");
                dispatch(createSample({
                    apiBaseUrl: apiBaseUrl,
                    projectId: projectId,
                    projectName: allService.project.currProject?.name,
                    requestBody: formData,
                    cancelTokenSource: cancelTokenSource,
                    index,
                }));
            }
        },
        validationSchema: Yup.object().shape({
            'sample_name': Yup.string()
                .required('Please provide a name for your sample.'),
            'forward_zip_file': Yup.mixed()
                .required('Please upload your forward zip file.')
                .test('fileFormat', 'Please upload a .gz/.gzip file', value => {
                    return value && (value.name.endsWith('.gz') || value.name.endsWith('.gzip'));
                }),
            'reverse_zip_file': Yup.mixed()
                .test('fileFormat', 'Please upload a .gz/.gzip file/.gzip file', value => {
                    return value && (value.name.endsWith('.gz') || value.name.endsWith('.gzip'));
                })
                .required('Please upload your reverse zip file.'),
        }),
    });
    const readFileInChunks = async(file, chunkSize, onChunkRead) => {
        const fileReader = new FileReader();
        let offset = 0;
        return new Promise((resolve, reject) => {
            fileReader.onerror = () => {
                fileReader.abort();
                reject(new Error('Error reading file.'));
            };
            fileReader.onload = async (e) => {
                const chunk = new Uint8Array(e.target.result);
                await onChunkRead(chunk);
                offset += chunk.length;
                if (offset < file.size) {
                    readNextChunk();
                } else {
                    resolve();
                }
            };
            const readNextChunk = () => {
                const slice = file.slice(offset, offset + chunkSize);
                fileReader.readAsArrayBuffer(slice);
            }
            readNextChunk();
        });
    };
    const calculateSHA1Checksum = async(file) => {
        const chunkSize = 1024 * 1024; // 1 MB
        const shaObj = new jsSHA("SHA-1", "ARRAYBUFFER");
        await readFileInChunks(file, chunkSize, (chunk) => {
            shaObj.update(chunk);
        });
        return shaObj.getHash("HEX");
    };
    const forwardGZIPOnChangeHandler = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        await formik.setFieldValue('forward_zip_file', file);
    }
    const reverseGZIPOnChangeHandler = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        await formik.setFieldValue('reverse_zip_file', file);
    }
    const onCloseHandler = () => {
        forwardFileInput.current.value = '';
        reverseFileInput.current.value = '';
        formik.resetForm();
        document.getElementById('create_sample_modal').close();
    }
    return (
        <dialog id="create_sample_modal" className="modal md:modal-middle">
            <div className="modal-box">
                <form
                    method={"dialog"}
                    className={"flex flex-col items-center py-4"}
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className={"mb-8 font-bold text-xl md:text-3xl"}>Create Sample</h1>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"sample_name"}
                            type={"text"}
                            placeholder={"Sample Name*"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.name && !!formik.errors.name && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values['sample_name']}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched["sample_name"] && formik.errors["sample_name"]}</span>
                        </label>
                    </div>
                    <div>
                        <div className={"form-control w-full max-w-xs"}>
                            <label className={"label"}>
                                <span className={"label-text text-gray-500"}>Forward GZip File*</span>
                            </label>
                            <input
                                type={"file"}
                                ref={forwardFileInput}
                                className={"file-input file-input-bordered w-full"}
                                onChange={forwardGZIPOnChangeHandler}
                            />
                            <label className={"label"}>
                                <span className="label-text-alt text-red-500">{formik.touched['forward_zip_file'] && formik.errors['forward_zip_file']}</span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className={"form-control w-full max-w-xs"}>
                            <label className={"label"}>
                                <span className={"label-text text-gray-500"}>Reverse GZip File*</span>
                            </label>
                            <input
                                type={"file"}
                                ref={reverseFileInput}
                                className={"file-input file-input-bordered w-full"}
                                onChange={reverseGZIPOnChangeHandler}
                            />
                            <label className={"label"}>
                                <span className="label-text-alt text-red-500">{formik.touched['reverse_zip_file'] && formik.errors['reverse_zip_file']}</span>
                            </label>
                        </div>
                    </div>
                    <div className={"pt-4"}>
                        <button
                            type={"submit"}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-info"}
                        >
                            Save
                        </button>
                        <button
                            type={"button"}
                            onClick={onCloseHandler}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    )
}
export default CreateSampleDialog;