import * as d3 from "d3";

export const drawAxis2 = (
    svg,
    scale,
    orient,
    pos,
    title = "",
    tpos = null,
    trot = null
) => {
    let [f, x, y, tx, ty, r] = {
        top: [d3.axisTop, 0, pos, d3.sum(scale.range()) / 2, -30, 0],
        bottom: [d3.axisBottom, 0, pos, d3.sum(scale.range()) / 2, 55, 0],
        left: [d3.axisLeft, pos, 0, -40, d3.sum(scale.range()) / 2, 270],
        right: [d3.axisRight, pos, 0, 30, d3.sum(scale.range()) / 2, 90]
    }[orient];
    if (tpos != null) [tx, ty] = tpos;
    if (trot != null) r = trot;
    let a = tpos != null ? "start" : "middle";
    let text = null;

    let g = svg
        .append("g")
        .attr("transform", `translate(${x}, ${y})`)
        .call(f(scale));

    if (title !== "")
        text = g
            .append("text")
            .style("fill", "black")
            .attr("class", "titleClass")
            .style("font", "14px Arial")
            .attr("text-anchor", a)
            .attr("transform", `translate(${tx},${ty}) rotate(${r})`)
            .html(title);

    if (orient === "bottom")
        svg
            .selectAll("text:not(.titleClass)")
            .attr("transform", "translate(-10,15) rotate(-45)");
    return text;
}

export const margin = {
    left: 55,
    top: 10,
    right: 10,
    bottom: 70
};

export const customColors = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
    "#aec7e8",
    "#ffbb78",
    "#98df8a",
    "#ff9896",
    "#c5b0d5",
    "#c49c94",
    "#f7b6d2",
    "#c7c7c7",
    "#dbdb8d",
    "#9edae5"
];
