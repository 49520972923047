import React, {useEffect} from "react";
import Navigation from "./components/Navigation/Navigation";
import Toast from "./Toast";
import UploadStatusBox from "./UploadStatusBox";
import {useSelector} from "react-redux";

const App = (props) => {
    const allService = useSelector(state => state.allService);
    useEffect(() => {
        const warnUser = (event) => {
            if (allService.uploadStatus) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', warnUser);
        return () => {
            window.removeEventListener('beforeunload', warnUser);
        };
    }, [allService.uploadStatus]);
    return (
        <div>
            {
                !window.location.href.includes('visualization') &&
                <div>
                    <Navigation/>
                    <Toast/>
                    {
                        allService.uploadStatus &&
                        <UploadStatusBox/>
                    }
                </div>
            }
            <main className={'mt-16'}>
                {props.children}
            </main>
        </div>
    );
}

export default App;