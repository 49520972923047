import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {editProject} from "../../../slices/allServiceSlice";
import {useConfig} from "../../../shared/config/ConfigContext";
import {useEffect} from "react";

const EditProjectDialog = ( { projectId } ) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            affiliation: '',
            'contact_email': '',
            'assoc_paper_url': '',
            'pubmed_id': '',
        },
        onSubmit: async (values) => {
            const result = await dispatch(editProject({apiBaseUrl, projectId, requestBody: values}));
            if (result)
                onCloseHandler();
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required('Please provide a project name.'),
            description: Yup.string()
                .required('Please provide a description for the project.'),
            affiliation: Yup.string()
                .required('Please provide your affiliation.'),
            contact_email: Yup.string()
                .email('Please enter a valid email address.')
                .required('Please provide your contact email.'),
        })
    });
    useEffect(() => {
        formikResetHandler();
    }, [allService.project.currProject])
    const formikResetHandler = () => {
        formik.setValues({
            name: allService.project.currProject?.name,
            description: allService.project.currProject?.description || '',
            affiliation: allService.project.currProject ? allService.project.currProject.affiliation : '',
            'contact_email': allService.project.currProject ? allService.project.currProject['contact_email'] : '',
            'assoc_paper_url': allService.project.currProject ? allService.project.currProject['assoc_paper_url'] : '',
            'pubmed_id': allService.project.currProject ? allService.project.currProject['pubmed_id'] : '',
        });
    }
    const onCloseHandler = () => {
        document.getElementById('edit_project_modal').close();
        formikResetHandler();
    }
    return (
        <dialog id="edit_project_modal" className="modal md:modal-middle">
            <div className="modal-box">
                <form
                    method={"dialog"}
                    className={"flex flex-col items-center py-4"}
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className={"mb-8 font-bold text-xl md:text-3xl"}>Edit Project</h1>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"name"}
                            type={"text"}
                            placeholder={"Project Name*"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.name && !!formik.errors.name && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.name && formik.errors.name}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <textarea
                            id={"description"}
                            placeholder={"Description*"}
                            className={`textarea textarea-sm md:textarea-md textarea-bordered w-full max-w-xs resize-none ${formik.touched.name && !!formik.errors.name && "textarea-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.description && formik.errors.description}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"affiliation"}
                            type={"text"}
                            placeholder={"Affiliation*"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched.affiliation && !!formik.errors.affiliation && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values.affiliation}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched.affiliation && formik.errors.affiliation}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"contact_email"}
                            type={"text"}
                            placeholder={"Contact Email*"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched["contact_email"] && !!formik.errors["contact_email"] && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values["contact_email"]}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched["contact_email"] && formik.errors["contact_email"]}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"pubmed_id"}
                            type={"text"}
                            placeholder={"Pubmed ID"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched["pubmed_id"] && !!formik.errors["pubmed_id"] && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values["pubmed_id"]}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched["pubmed_id"] && formik.errors["pubmed_id"]}</span>
                        </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <input
                            id={"assoc_paper_url"}
                            type={"text"}
                            placeholder={"Associated Paper"}
                            className={`input input-sm md:input-md input-bordered w-full max-w-xs ${formik.touched["assoc_paper_url"] && !!formik.errors["assoc_paper_url"] && "input-error"}`}
                            onChange={formik.handleChange}
                            value={formik.values["assoc_paper_url"]}
                        />
                        <label className="label">
                            <span className="label-text-alt text-red-500">{formik.touched["assoc_paper_url"] && formik.errors["assoc_paper_url"]}</span>
                        </label>
                    </div>
                    <div>
                        <button
                            type={"submit"}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-info"}
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </button>
                        <button
                            type={"button"}
                            onClick={onCloseHandler}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    );
}

export default EditProjectDialog;