import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAJob, getResults, getSteps} from "../../../../slices/allServiceSlice";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {RouteConstants} from "../../../../shared/constants/RouteConstants";
import {formatDateTime} from "../../../../shared/utils/DataConverter";
import StopJobConfirmDialog from "./StopJobConfirmDialog";
import DeleteJobConfirmDialog from "./DeleteJobConfirmDialog";
import Step from "./Step";
import Result from "./Result";
import {Link} from "react-router-dom";

const JobDetail = (props) => {
    const dispatch = useDispatch();
    const jobId = props.match.params.jobId;
    const projectId = props.match.params.projectId;
    const allService = useSelector(state => state.allService);
    const { apiBaseUrl, pollRefreshSeconds } = useConfig();
    useEffect(() => {
        const fetchData = () => {
            dispatch(getAJob({ apiBaseUrl, jobId }));
            dispatch(getResults({ apiBaseUrl, jobId }));
            dispatch(getSteps({ apiBaseUrl, jobId }));
        };
        fetchData();
        const intervalId = setInterval(fetchData, pollRefreshSeconds * 1000);
        return () => clearInterval(intervalId);
    }, [dispatch, allService.changed, apiBaseUrl, projectId, jobId, pollRefreshSeconds])
    const stopJobHandler = () => {
        document.getElementById('stop_job_confirm_modal').showModal();
    }
    const deleteJobHandler = () => {
        document.getElementById('delete_job_confirm_modal').showModal();
    }
    return (
        <div className={"flex lg:mt-8 flex-col lg:mx-auto p-5 overflow-x-auto lg:w-3/4"}>
            <div className={"breadcrumbs flex-shrink-0 lg:mb-4 text-xs lg:text-lg"}>
                <ul>
                    <li><Link to={RouteConstants.homeRoute}>Home</Link></li>
                    <li><Link to={RouteConstants.projectsRoute}>Project</Link></li>
                    <li><Link to={`${RouteConstants.projectsRoute}/${projectId}`}><span className={"max-w-[20vw] truncate"}>{allService.currJob?.sample.project.name}</span></Link></li>
                    <li className={"max-w-xs truncate"}><span className={"max-w-[20vw] truncate"}>{allService.currJob?.name}</span></li>
                </ul>
            </div>
            <div className={"flex items-center"}>
                <h1 className={"font-bold lg:text-5xl text-3xl truncate max-w-3/4 lg:mr-8 mr-4 leading-normal lg:h-16"}>{allService.currJob?.name}</h1>
                <div className={"ml-auto flex flex-col lg:flex-row items-center"}>
                    <button
                        className={"btn btn-outline btn-error lg:px-8 lg:btn-md btn-xs w-full lg:w-auto mb-2 lg:mb-0 lg:mr-4 lg:px-12 px-8"}
                        disabled={allService.currJob?.job_status_type.terminal_state_ind || allService.loading}
                        onClick={stopJobHandler}
                    >
                        Stop
                    </button>
                    <button
                        className={"btn btn-outline btn-error lg:px-8 lg:btn-md btn-xs w-full lg:w-auto lg:px-12 px-8"}
                        disabled={!allService.currJob?.job_status_type.terminal_state_ind || allService.loading}
                        onClick={deleteJobHandler}
                    >
                        Delete
                    </button>
                </div>
            </div>
            <div className={"py-4"}>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Sample :</span>
                    </div>
                    <span className={"flex-1"}>{allService.currJob?.sample.name}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Pipeline :</span>
                    </div>
                    <span className={"flex-1"}>{allService.currJob?.pipeline.name}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Status :</span>
                    </div>
                    <span className={"flex-1"}>{allService.currJob?.job_status_type.name}</span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Last Completed Step :</span>
                    </div>
                    <span className={"flex-1"}>
                        {
                            allService.currJob && allService.currJob['mrt_job_pipeline_step'] ?
                                allService.currJob['mrt_job_pipeline_step']['pipeline_step']['name']
                                :
                                "None"
                        }
                    </span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Submitted At :</span>
                    </div>
                    <span className={"flex-1"}>
                        {
                            allService.currJob?.created_at ?
                                formatDateTime(allService.currJob?.created_at)
                                :
                                ""
                        }
                    </span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Started At :</span>
                    </div>
                    <span className={"flex-1"}>
                        {
                            allService.currJob?.started_at ?
                                formatDateTime(allService.currJob?.started_at)
                                :
                                "Not Started"
                        }
                    </span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Completed At :</span>
                    </div>
                    <span className={"flex-1"}>
                        {
                            allService.currJob?.terminated_at ?
                                formatDateTime(allService.currJob?.terminated_at)
                                :
                                "Not Completed"
                        }
                    </span>
                </div>
                <div className={"lg:flex py-2 lg:text-lg text-sm"}>
                    <div className={"lg:w-[200px] lg:text-right lg:mr-16"}>
                        <span className={"font-bold"}>Metadata :</span>
                    </div>
                    <span className={"flex-1"}>{allService.currJob?.metadata_file_name || String.fromCharCode(160)}</span>
                </div>
            </div>
            <Step/>
            <Result jobName={allService.currJob?.name} jobId={jobId}/>
            <StopJobConfirmDialog jobId={jobId}/>
            <DeleteJobConfirmDialog projectId={projectId} jobId={jobId}/>
        </div>
    )
}

export default JobDetail;